import { useState, useEffect } from "react";
import {
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import {
  OwcButton,
  OwcIconButton,
  OwcProgressBar,
  OwcCard,
  OwcHyperlink,
  OwcList,
  OwcListItem,
} from "@one/react";

function getAppForAlias(tenantsApps, appAlias) {
  for (let x = 0; x < tenantsApps.length; x++) {
    let a = tenantsApps[x];
    if (a.appAlias === appAlias) {
      return a;
    }
  }
}

export default function SelectedApp(props) {
  const appData = useLoaderData();
  const navigate = useNavigate();
  const [selectedAppTenant, setSelectedAppTenant] = useState(null);
  const [launchAppUrlValue, setLaunchAppUrlValue] = useState("");

  const [launchTimeoutId, setLaunchTimeoutId] = useState(null);

  const [cancelled, setCancelled] = useState(false);
  const [finishedLaunching, setFinishedLaunching] = useState(false);

  function launchAppUrl(url) {
    if (launchTimeoutId == null) {
      let id = setTimeout(() => {
        if (!cancelled) {
          setFinishedLaunching(true);
          window.location.href = url;
        }
      }, 1250);
      setLaunchTimeoutId(id);
    }
  }

  function cancelLaunch() {
    if (launchTimeoutId != null) {
      clearTimeout(launchTimeoutId);
      setLaunchTimeoutId(null);
    }
    setCancelled(true);
    navigate(`/apps/${appData.app.appAlias}`)
  }

  function cancellApp() {
    if (launchTimeoutId != null) {
      clearTimeout(launchTimeoutId);
      setLaunchTimeoutId(null);
    }
    if (props.onClearApp != null) {
      props.onClearApp();
    }
    setCancelled(true);
    navigate("/")
  }

  function selectTenant(tenant, appAlias) {
    let customizedApp = getAppForAlias(tenant.apps, appData.app.appAlias);
    setSelectedAppTenant(tenant);
    setLaunchAppUrlValue(customizedApp.url);
    launchAppUrl(customizedApp.url);
    setCancelled(false);
  }

  useEffect(() => {
    if (appData.tenants.length === 1) {
      selectTenant(appData.tenants[0], appData.app.appAlias);
    }
  }, [appData.tenants, appData.appAlias, selectedAppTenant]);

  return (
    <div>
      <h3>
        App: {appData.app.appName}
        <OwcIconButton
          className="float-right"
          onClick={cancellApp}
          icon="clear"
        />
      </h3>
      {!selectedAppTenant && (
        <div>
          <h5>Select your tenant</h5>
          <OwcList className="tenant-list">
            {appData.tenants.map((tenant) => (
              <OwcListItem
                key={tenant.uuid}
                onClick={(e) => {
                  selectTenant(tenant, props.appAlias);
                }}
              >
                <span>{tenant.name}</span>
                <br />
                <span className="tenant-option-alias">{tenant.alias}</span>
              </OwcListItem>
            ))}
          </OwcList>
        </div>
      )}

      {selectedAppTenant && (
        <div className="full-width">
          <OwcCard>
            <div slot="header">Tenant: {selectedAppTenant.name}</div>
            <div className="full-width">
              {!cancelled && (
                <OwcProgressBar caption={"Launching..."} value={100} />
              )}
              {cancelled && <span>Cancelled Auto-Launch</span>}
            </div>
            <div slot="footer">
              <OwcButton
                style={{ float: "right" }}
                disabled={finishedLaunching || cancelled}
                onClick={(e) => {
                  cancelLaunch();
                }}
              >
                Cancel
              </OwcButton>
              <OwcHyperlink style={{ float: "left" }} href={launchAppUrlValue}>
                Launch Manually
              </OwcHyperlink>
            </div>
          </OwcCard>
        </div>
      )}
    </div>
  );
}
