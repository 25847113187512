import axios from "axios";

export default async function whoami() {
  let url =
    "https://" +
    (process.env.NAVIFY_API ?? "api.npsandbox01.sandbox.rginger.com") +
    `/api/v1/users/whoami`;
  return axios.get(url, {
    withCredentials: true,
  });
}
