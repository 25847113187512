import { OwcList, OwcListItem } from "@one/react";
import { useNavigate } from "react-router-dom";

export default function AppList(props) {
  const appKeys = Object.keys(props.apps).sort();
  const navigate = useNavigate();

  function selectApp(e) {
    const appIndex = e.detail[0];
    if (
      appIndex &&
      props.onSelectApp !== undefined &&
      props.apps[appKeys[appIndex]]
    ) {
      const app = props.apps[appKeys[appIndex]].app;
      props.onSelectApp(app.appAlias);
      navigate(`/apps/${app.appAlias}`);
    }
  }

  return (
    <div>
      <h3>Select your app</h3>
      <OwcList className="app-list" onSelectChange={selectApp}>
        {appKeys.map((alias, i) => (
          <OwcListItem key={alias}>
            <span>{props.apps[alias].app.appName}</span>
          </OwcListItem>
        ))}
      </OwcList>
      {appKeys.length == 0 && (
        <div>Sorry, you don't seem to have access to any Navify apps</div>
      )}
    </div>
  );
}
