import { useState, useEffect } from "react";
import {
  Outlet,
  Link,
  createBrowserRouter,
  RouterProvider,
  useSearchParams,
  useNavigate,
  Navigate
} from "react-router-dom";
import {
  OwcCard,
  OwcIconButton,
  OwcButton,
  OwcProgressSpinner,
  OwcList,
  OwcListItem,
} from "@one/react";

import axios from "axios";
import whoami from "./Api.js";
import SelectedApp from "./SelectedApp.js";
import AppList from "./AppList.js";

export default function AppSelection(props) {

  const [loading, setLoading] = useState(false);
  const [raw, setRaw] = useState({});
  const [tenantsForApp, setAppTenants] = useState({});
  const [tenants, setTenants] = useState([]);
  const [apps, setApps] = useState({});
  const [selectedApp, setSelectedApp] = useState(null);
  const [launchAppUrlValue, setLaunchAppUrl] = useState("");
  const [selectedAppTenants, setSelectedAppTenants] = useState([]);

  const [selectedAppTenant, setSelectedAppTenant] = useState(null);

  // TODO: implement querystring/routing
  // const [searchParams, setSearchParams] = useSearchParams();
  // const preselectedApp = searchParams.get('app');
  const preselectedApp = null;

  let launchTimeoutId = null;

  function launchApp(tenant, app, e) {
    for (let i = 0; i < tenants.length; i++) {
      for (let x = 0; x < tenants[i].apps.length; x++) {
        let a = tenants[i].apps[x];
        if (a.appAlias === app) {
          setSelectedAppTenant(tenantsForApp[tenant]);
          setLaunchAppUrl(a.url);
          launchAppUrl(a.url);
          return;
        }
      }
    }
  }

  function launchAppUrl(url) {
    launchTimeoutId = setTimeout(() => {
      window.location.href = url;
    }, 1500);
    setLaunchAppUrl(url);
  }

  function cancelLaunch() {
    clearTimeout(launchTimeoutId);
  }

  function selectApp(app) {
    const app_obj = apps[app];
    if(app_obj != undefined){
      setSelectedApp(app_obj.app);
      setSelectedAppTenants(app_obj.tenants);
    }
  }

  function clearSelectedApp() {
    setSelectedApp(null);
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <AppList
          apps={apps}
          onSelectApp={(appAlias) => {
            selectApp(appAlias);
          }}
        />
      ),
      children: [],
    },
    {
      path: "apps/:appName",
      loader: (e) => {
        //alert(e.params.appName);
        
        return {
          app: apps[e.params.appName].app,
          tenants: apps[e.params.appName].tenants
        };
      },
      element: (
        <SelectedApp
          // appName={selectedApp?.appName}
          // app={selectedApp}
          // tenants={selectedAppTenants}
          onClearApp={clearSelectedApp}
        />
      ),
    }
  ]);

  useEffect(() => {
    setLoading(true);
    whoami().then((res) => {
      const whoami = res.data;
      setRaw(whoami);
      setTenants(whoami.tenants);
      let apps = {};
      let tenants = {};
      for (let i = 0; i < whoami.tenants.length; i++) {
        let t = whoami.tenants[i];
        for (let x = 0; x < t.apps.length; x++) {
          let a = t.apps[x];
          if (!(a.appAlias in apps)) {
            apps[a.appAlias] = {
              app: a,
              tenants: [],
            };
          }
          apps[a.appAlias].tenants.push(t);
          tenants[t.uuid] = t;
        }
      }
      setApps(apps);
      setAppTenants(tenants);
      if (preselectedApp != null) {
        alert(preselectedApp);
      }
      setLoading(false);
    });
  }, []);
  return (
    <div>
      {loading && (
        <div>
          <OwcProgressSpinner diameter={100} value={50} />
        </div>
      )}

      {/* {!loading && selectedApp && (
        //TODO: migrate to router
        // <Navigate to={`/apps/${selectApp.appAlias}`} replace={true} />
        // <div>
        //   <SelectedApp
        //     appName={selectedApp.appName}
        //     app={selectedApp}
        //     tenants={selectedAppTenants}
        //     onClearApp={clearSelectedApp}
        //   ></SelectedApp>
        // </div>
      )} */}
      {!loading && (
        <div>
          <RouterProvider router={router} />
          {/* <hr />
          <AppList
            apps={apps}
            onSelectApp={(appAlias) => {
              selectApp(appAlias);
            }}
          ></AppList> */}
        </div>
      )}
    </div>
  );
}
