import "./App.css";
import React, { useState, useEffect } from "react";

import { Env } from "@navify-platform/env";
import { Auth } from "@navify-platform/auth";
import AppSelection from "./AppSelection.js";

import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SelectedApp from "./SelectedApp";
import {
  OwcProgressSpinner,
  OwcHyperlink,
  OwcDoubleGlobalArea,
  OwcIconButton,
  OwcButton,
} from "@one/react";

// TODO: better paramaterize

const platformEnv = new Env({
  props: {
    urlScheme: "https:",
    platformApiUrl:
      process.env.NAVIFY_API ?? "api.npsandbox01.sandbox.rginger.com",
    authUiUrl:
      process.env.NAVIFY_AUTH_UI ??
      "roche.authapp.npsandbox01.sandbox.rginger.com",
    appAlias: "navify-app-launcher",
    tenantAlias: null,
  },
});

const authConf = {
  env: platformEnv,
};

const auth = new Auth(authConf);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setAuthSessionProfile] = useState(null);
  //const [authSessionExpires, setAuthSessionExpires] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginErrorMessage, setLoginError] = useState(null);

  function loginError(err) {
    // TODO: handle error
    setLoginError({
      connection: err.faults.connectionError,
      message: err.message,
    });
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      await auth.init();
      auth.subscribe((event) => {
        // TODO: event processing
      });
      auth
        .getSession()
        .then((session) => {
          setIsLoading(false);
          if (session != null) {
            setAuthSessionProfile(session.profile);
            // TODO: refresh before expires
            //setAuthSessionExpires(session.expiresAt);
            setIsAuthenticated(true);
          }
        })
        .catch((err) => {
          loginError(err);
        });
    }
    fetchData();
  }, []);

  async function login() {
    await auth.loginRedirect();
  }

  async function logout() {
    await auth.logoutRedirect();
  }


  // auth.getLoginReturn().then((login_success) => {
  //   // TODO: process errors
  //   console.log(login_success);
  // });

  return (
    <div className="App">
      <OwcDoubleGlobalArea>
        <div slot="top">
          <div className="global-header">
            <div
              className="navify-logo"
              style={{ flexBasis: "32%", textAlign: "left" }}
            >
              <p>navify</p>
            </div>
            <div className="global-app-name">
              <p>Navify App Launcher</p>
            </div>
            <div style={{ flexBasis: "32%", textAlign: "right" }}>
              {!isLoading && isAuthenticated && (
                <div style={{ display: "inline-flex" }}>
                  <p style={{ paddingRight: "10px" }}>
                    Welcome, {profile.username}{" "}
                  </p>
                  <OwcIconButton icon={"log_off"} onClick={logout}>
                    Logout
                  </OwcIconButton>
                </div>
              )}
              {!isLoading && !isAuthenticated && (
                <div style={{ display: "flex" }}>
                  {/* <div>
                    Please Login
                    <OwcIconButton icon={"log_on"} onClick={() => login()}>
                      Login
                    </OwcIconButton>
                  </div> */}

                  {/* <OwcHyperlink href={"/"} target="_self">
                  Retry
                </OwcHyperlink> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </OwcDoubleGlobalArea>

      <div>
        <header className="App-header">
          <div></div>
          <div className="App-body">
            {isLoading && (
              <div>
                <OwcProgressSpinner />
              </div>
            )}
            {isAuthenticated && (
              <div>
                <AppSelection />
              </div>
            )}
            {!isLoading && !isAuthenticated && (
              <div className="center">
                <h3>Please Login</h3>
                <div className="center">
                  <OwcButton
                    className="inline-block"
                    icon={"log_on"}
                    onClick={() => login()}
                  >
                    Login
                  </OwcButton>
                </div>
              </div>
            )}
            {loginErrorMessage && (
              <div className="error-message">
                {loginErrorMessage.connection && <span>Connection Error!</span>}
              </div>
            )}
          </div>
        </header>
      </div>
    </div>
  );
}

export default App;
